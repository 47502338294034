<div class="container">
  <div class="content">
      <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="hasInited">
  <p-dialog *ngIf=" isLoadingSpinnerVisible | async" class="spinner-loading" [style]="{background: 'transparent'}" [visible]=true [closable]="false" [modal]="true">
    <p-progressSpinner ></p-progressSpinner>
  </p-dialog>
</div>