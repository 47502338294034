import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateRoute } from './guard';

const routes: Routes = [
  {
    path: '', 
    // canActivate: [canActivateRoute], fix guards
    loadChildren: () => import('./pensao/cadastro-requerimento/cadastro-requerimento.module').then(m => m.CadastroRequerimentoModule) 
  },
  { 
    path: 'pensao/cadastro-requerimento',
    // canActivate: [canActivateRoute], TODO: fix guards
    loadChildren: () => import('./pensao/cadastro-requerimento/cadastro-requerimento.module').then(m => m.CadastroRequerimentoModule) 
  },
  {
    path: '**', 
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
