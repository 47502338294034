import { AuthenticationService, SpinnerService } from '@sgprev-services';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'sgprev-ui';
    public hasInited = false;

    constructor(private authService: AuthenticationService, public spinnerService: SpinnerService) { }
    
    public get isLoadingSpinnerVisible(): BehaviorSubject<boolean> {
        return this.spinnerService.isLoaging;
    }
    
    ngOnInit(): void {
        this.loadTokenFromParameters();
        console.log('TOKEN', this.authService.token);
    }

    private loadTokenFromParameters(): void {
        const urlParams = new URLSearchParams(window.location.search);
        this.authService.token = urlParams.get('token');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.hasInited = true;
        }, 300);
    }
}
