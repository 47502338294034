import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "@sgprev-services";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.token as string;
        const request = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        });

        return next.handle(request);
    }
}

// export const demoInterceptor: HttpInterceptorFn = (req, next) => {
//     const authToken = 'YOUR_AUTH_TOKEN_HERE';
  
//     // Clone the request and add the authorization header
//     const authReq = req.clone({
//       setHeaders: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });
  
//     // Pass the cloned request with the updated header to the next handler
//     return next(authReq);
//   };